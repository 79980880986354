<template>
  <b-row>
    <b-col sm="12" md="8">
      <my-card title="Sortir" collapsed :badge="$store.getters['receipts/isFiltered'] ? 'Filtered':''">
        <template slot="body">
          <form @submit.prevent="submitFilter">
            <b-row>
              <b-col sm="12">
                <form-select-new :filter-id="idBranchArray" url="v1/branch_select2" :queryparams="{id_regional: mainRegionalId}" v-model="filter.branch" label="Cabang Asal"></form-select-new>
              </b-col>
              <b-col sm="12">
                <b-form-group label-for="radio-group-type" label="Tipe">
                  <b-form-radio-group
                  id="radio-group-type"
                  v-model="filter.type"
                  :options="utils.types"
                  text-field="label"
                  >
                  </b-form-radio-group>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <form-date :max="maxDate" v-model="filter.date_1" label="Tanggal Mulai"></form-date>
              </b-col>
              <b-col sm="6">
                <form-date :min="minDate" v-model="filter.date_2" label="Tanggal Selesai"></form-date>
              </b-col>
              <b-col sm="12" v-show="filter.type==1">
                <form-select-new url="v1/branch_select2" :queryparams="{id_regional: mainRegionalId}" v-model="filter.branch_sender" label="Cabang Tujuan"></form-select-new>
              </b-col>
              <b-col sm="12" v-show="filter.type==2">
                <form-select-new url="v1/supplier_select2" v-model="filter.supplier" label="Pemasok"></form-select-new>
              </b-col>
              <b-col sm="12">
                <form-select-static :options="utils.statuses" v-model="filter.id_status" label="Status"></form-select-static>
              </b-col>
              <b-col sm="12">
                <button-action size="sm" @clicked="submitFilter" name="Filter" featherIcon="FilterIcon"></button-action>
                <button-action size="sm" @clicked="resetFilter" name="Reset" variant="warning"></button-action>
                <button-action v-if="cP(133)" size="sm" @clicked="exportExcel" variant="warning" name="Export"></button-action>
              </b-col>
            </b-row>
          </form>
        </template>
      </my-card>
    </b-col>
    <b-col sm="12">
      <my-card title="Daftar Penerimaan">
        <template slot="body">
          <data-tables sort-key="3" desc ref="datatables" endpoint="v1/receptions" :query-params="filterQuery" :fields="fields">
            <template v-slot:1="{item}">
              <b-badge v-if="item[1]===`(belum ada penerimaan)`" variant="warning">{{item[1]}}</b-badge>
              <span v-else>{{item[1]}}</span>
            </template>
            <template v-slot:3="{item}">
              {{item[3] | mindate}}
            </template>
            <template v-slot:6="{item}">
              {{item[6] | formatNumber}}
            </template>
            <template v-slot:7="{item}">
              <button-link size="sm" v-if="item[10]" :to="{name:'receipts-show',params:{id: item[7]}}" feather-icon="FolderIcon"></button-link>
              <div class="float-none" v-if="!item[10]">
                <span v-if="[4,5].includes(item[8])">
                  <button-link v-if="item[8]==5&&cP(92)" size="sm" :to="{name:'receipts-part',params:{id: item[7]}}" feather-icon="PlusIcon"></button-link>
                  <button-link v-if="cP(94)" size="sm" :to="{name:'receipts-edit',params:{id: item[7]}}" feather-icon="EditIcon"></button-link>
                </span>
                <button-link v-else-if="item[9]==1&&cP(92)" size="sm" :to="{name:'receipts-transfer',params:{id: item[7]}}" feather-icon="PlusIcon"></button-link>
                <button-link v-else-if="item[9]==2&&cP(92)" size="sm" :to="{name:'receipts-purchase',params:{id: item[7]}}" feather-icon="PlusIcon"></button-link>
                <button-delete v-if="[4,5].includes(item[8])&&cP(95)" @ondelete="deleteRow" :id="item[7]" :name="`${item[1]} (${item[2]})`"></button-delete>
              </div>
            </template>
          </data-tables>
        </template>
      </my-card>
    </b-col>
  </b-row>
</template>

<script>
import ButtonAction from '@/my-components/ButtonAction.vue'
import DataTables from '@/my-components/DataTables.vue'
import ButtonDelete from '@/my-components/ButtonDelete.vue'
import ButtonLink from '@/my-components/ButtonLink.vue'
import FormSelectNew from '@/my-components/FormSelectNew'
import FormSelectStatic from '@/my-components/FormSelectStatic'
import FormDate from '@/my-components/FormDate'
import { mapFields } from 'vuex-map-fields'
import utils from './utils'
import {BBadge,BFormRadioGroup,BFormGroup} from 'bootstrap-vue'
import FileSaver from 'file-saver'

export default {
  components:{
    DataTables,
    ButtonDelete,
    ButtonLink,
    FormSelectNew,
    FormDate,
    ButtonAction,
    FormSelectStatic,
    BBadge,
    BFormRadioGroup,
    BFormGroup
  },
  data() {
    return {
      utils:utils,
      fields: {
        0:{sortable: true, searchable: true, label:'Kode Transfer / Pembelian'},
        1:{sortable: true, searchable: true, label:'Kode'},
        2:{sortable: true, searchable: true, label:'Cabang'},
        3:{sortable: true, searchable: true, label:'Tanggal'},
        4:{sortable: true, searchable: true, label:'Cabang Tujuan/Pemasok'},
        5:{sortable: true, searchable: true, label:'Status'},
        6:{sortable: true, searchable: true, label:'Total'},
        7:{sortable: false, searchable: false, label:''},
      }
    }
  },
  computed:{
    ...mapFields('receipts',['filter']),
    userProfile(){
      return this.$store.state.auth.profile
    },
    minDate(){
      const min = this.filter.date_1
      if(!min) return null
      else return this.$moment(min).format()
    },
    maxDate(){
      const max = this.filter.date_2
      if(!max) return null
      else return this.$moment(max).format()
    },
    filterQuery(){
      let filters = JSON.parse(JSON.stringify(this.filter))
      if(filters.branch) filters.id_branch = filters.branch.value
      if(filters.branch_sender) filters.id_branch_sender = filters.branch_sender.value
      if(filters.supplier) filters.id_supplier = filters.supplier.value
      delete filters.branch
      delete filters.branch_sender
      delete filters.supplier
      return filters
    },

  },
  methods:{
    firstInit(){
      if(!this.isPusat) {
        if(!this.filter.branch)
        this.filter.branch = {value:this.userProfile.id_branch,label:this.userProfile.branch_name}
      }
    },
    submitFilter(){
      this.$refs.datatables.refresh()
    },
    resetFilter(){
      if(this.isPusat){
        this.filter.id_branch = ""
        this.filter.branch = null
      }
      this.filter.id_supplier = ""
      this.filter.supplier = null
      this.filter.id_branch_sender = ""
      this.filter.branch_sender = null
      this.filter.type = ""
      this.filter.date_1 = ""
      this.filter.date_2 = ""
      this.filter.id_status = ""
      this.$refs.datatables.refresh()
    },
    async deleteRow(payload){
      try {
        await this.$store.dispatch('receipts/destroy',payload)
        this.$refs.datatables.refresh()
        this.toastSuccess("OK","Data Berhasil Dihapus!")
      } catch (error) {
        this.handleError(error)
      }
    },
    async exportExcel(){
      const filters = this.filter
      try {
        const res = await this.$http.get(`v1/reception_export`,{params:filters,responseType: 'blob'})
        FileSaver.saveAs(res.data,'PENERIMAAN')
      } catch (error) {
        this.handleError(error)
      }
    }
  },
  created(){
    this.firstInit()
  }
}
</script>

<style>

</style>